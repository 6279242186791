import React from "react";
import Hero from "../../components/hero";
import BlogCard from "../../components/blogCard";
import Layout from "../../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Link, useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Navigation from "../../components/navigation";
import { Helmet } from "react-helmet";

const BlogPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
        heroImg: wpMediaItem(title: { eq: "Blog-Hero" }) {
          altText
          localFile {
            publicURL
            childImageSharp {
              original {
                height
                width
              }
            }
          }
        }

        blogPosts: allWpPost(
          filter: { title: { ne: "Website Images" } }
          sort: { fields: dateGmt, order: DESC }
        ) {
          nodes {
            id
            title
            uri
            excerpt
            blogFields {
              featuredImage {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 100
                        placeholder: BLURRED
                        layout: CONSTRAINED
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  const heroImg = data.heroImg?.localFile.publicURL;
  const siteUrl = data.site.siteMetadata.siteUrl;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Blog",
        item: {
          url: `${siteUrl}/blog`,
        },
      },
    ],
  };

  return (
    <Layout>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <GatsbySeo
        title="Blog | Eastbourne Pilates"
        description="Eastbourne Pilates Blog / placholder"
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/blog`,
          title: "Blog | Eastbourne Pilates",
          description: "Eastbourne Pilates Blog / placholder",
          images: [
            {
              url: `${heroImg}`,
              width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
              height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
              alt: `${data.heroImg?.altText}`,
            },
          ],
        }}
      />
      <section>
        <section
          style={{
            background:
              "transparent linear-gradient(180deg, #E0F1F9 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
            opacity: 1,
          }}
        >
          <Navigation background="none" />
          <Container>
            {" "}
            <Row>
              <Col>
                <h1 className="text-primary mt-8 mb-5 text-center display-4 jost-bold">
                  Blog
                </h1>
              </Col>
            </Row>
          </Container>
        </section>
        <Container fluid={true} as="section" className="my-24">
          <Row className="justify-content-center">
            <Col xs={11} sm={10} xl={8}>
              <Row className="pt-4">
                {data.blogPosts.nodes.map((post) => (
                  <BlogCard
                    key={post.id}
                    title={post.title}
                    uri={post.uri}
                    image={
                      post.blogFields?.featuredImage?.node?.localFile
                        .childImageSharp.gatsbyImageData
                    }
                    excerpt={post.excerpt}
                    imageAlt={post.blogFields?.featuredImage?.node?.altText}
                  ></BlogCard>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default BlogPage;
